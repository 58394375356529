<div class="form-container single" *transloco="let t">
  @if (form) {
    <form [formGroup]="form">
      <div class="form-row margin-bottom-24">
        <app-dropdown
          class="form-singular-input-with-tooltip"
          [title]="t('difficultyLevel') + ' *'"
          [options]="difficultyOptions"
          formControlName="difficulty"
        ></app-dropdown>

        <app-tooltip [paragraphs]="[t('difficultyLevelTooltipP1'), t('difficultyLevelTooltipP2'), t('difficultyLevelTooltipP3')]">
        </app-tooltip>
      </div>

      <div class="form-row margin-bottom-24">
        <mat-form-field style="width: 164px" appearance="fill" class="form-field" color="accent">
          <mat-label>{{t('from')}}</mat-label>
          <input
            class="mat-input-with-img"
            matInput
            formControlName="fromDate"
            [max]="form.value['toDate'] ? form.value['toDate'] : defaultMaxDate"
            [matDatepicker]="fromPicker"
            />
            <svg class="img-inside-mat-input regular-img-size pointer icon-color" (click)="fromPicker.open()">
              <use xlink:href="assets/project-icons/calendar.svg#c"></use>
            </svg>
            <mat-datepicker #fromPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 164px" appearance="fill" class="form-field" color="accent">
            <mat-label>{{t('to')}}</mat-label>
            <input
              class="mat-input-with-img"
              matInput
              formControlName="toDate"
              [min]="form.value['fromDate'] ? form.value['fromDate'] : defaultMinDate"
              [matDatepicker]="toPicker"
              />
              <svg class="img-inside-mat-input regular-img-size pointer icon-color" (click)="toPicker.open()">
                <use xlink:href="assets/project-icons/calendar.svg#c"></use>
              </svg>
              <mat-datepicker #toPicker></mat-datepicker>
            </mat-form-field>

            <app-tooltip
              [title]="t('availabilityTooltip')"
              [paragraphs]="[t('availabilityTooltipP1'), t('availabilityTooltipP2'), t('availabilityTooltipP3')]"
              >
            </app-tooltip>
          </div>

          <div class="form-row margin-bottom-18">
            <mat-form-field appearance="fill" class="form-field form-singular-input-with-tooltip" color="accent">
              <mat-label>{{t('levelRequired')}}</mat-label>
              <input matInput type="number" formControlName="levelRequired" min="1" max="30">
            </mat-form-field>
            <app-tooltip [title]="t('levelRequired')"
              [paragraphs]="[t('levelRequiredTooltipP1'), t('levelRequiredTooltipP2')]">
            </app-tooltip>
          </div>

          <div class="form-row margin-bottom-18">
            <mat-form-field appearance="fill" class="form-field form-singular-input-with-tooltip" color="accent">
              <mat-label>{{t('approxDistance')}}</mat-label>
              <input matInput type="number" formControlName="approxDistance" min="0">
            </mat-form-field>
            <app-tooltip [title]="t('approxDistance')"
              [paragraphs]="[t('approxDistanceTooltipP1'), t('approxDistanceTooltipP2'), t('approxDistanceTooltipP3')]">
            </app-tooltip>
          </div>

          <div class="form-row margin-bottom-18">
            <mat-form-field appearance="fill" class="form-field form-singular-input-with-tooltip" color="accent">
              <mat-label>{{t('approxTime')}}</mat-label>
              <input class="step-timer" matInput formControlName="approxTime" type="text" mask="Hh:m0:s0" placeholder="00:00:00"/>
            </mat-form-field>
            <app-tooltip [title]="t('approxTime')"
              [paragraphs]="[t('approxTimeTooltipP1')]">
            </app-tooltip>
          </div>

          @if (!adaptive) {
            <div class="form-row margin-bottom-18">
              <mat-form-field appearance="fill" class="form-field form-singular-input-with-tooltip" color="accent">
                <mat-label>{{t('address')}}</mat-label>
                <input matInput type="text" formControlName="address" maxlength="100">
              </mat-form-field>
            </div>
          }

          <div class="form-row margin-bottom-24">
            <mat-slide-toggle formControlName="onlyOneCompletion" color="primary">{{t('onlyOneCompletion')}}</mat-slide-toggle>
            <app-tooltip [paragraphs]="[t('onlyOneCompletionTooltipP1')]">
            </app-tooltip>
          </div>

          <div class="form-row margin-bottom-24">
            <mat-slide-toggle formControlName="stepsHidden" color="primary">{{t('stepsHiddenForPlayer')}}</mat-slide-toggle>
            <app-tooltip [paragraphs]="[t('stepsHiddenForPlayerTooltipP1')]">
            </app-tooltip>
          </div>

          <div class="tags margin-bottom-18">
            @for (t of tags; track t) {
              <app-tag [text]="t.name"
                [selected]="t.selected"
                [disabled]="tagsSelectedCount >= MAX_NUMBER_OF_TAGS && !t.selected"
                (click)="clickOnTag(t)"
              ></app-tag>
            }
          </div>

          <mat-form-field appearance="fill" class="form-field mat-text-area margin-bottom-24" color="accent">
            <mat-label>{{t('description')}}</mat-label>
            <textarea matInput formControlName="description" rows="4" maxlength="2000"></textarea>
          </mat-form-field>

          <div class="form-row" style="justify-content: left">
            <span
              class="usual-flex-center general-big-text link-text"
              [ngClass]="{ disabled: getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES }"
              (click)="addPhotoClick()"
              >
              <svg class="regular-img-size">
                <use xlink:href="assets/project-icons/add.svg#a"></use>
              </svg>
              {{t('addPhoto')}}
            </span>
            <span
              class="usual-flex-center general-big-text link-text paste-photo-btn"
              [ngClass]="{ disabled: getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES }"
              (click)="clickPastePhoto()"
              >
              <svg class="regular-img-size">
                <use xlink:href="assets/project-icons/clipboard.svg#c"></use>
              </svg>
              {{t('pastePhoto')}}
            </span>
          </div>

          <div class="photos-row">
            @for (image of getCurrentImages(); track image; let i = $index) {
              <div class="img-thumbnail">
                <img [src]="image.url ? image.url : image.base" alt="" />
                <div class="icon-container edit">
                  <q-svg-wrapper class="small-icon pointer" [ngClass]="{'q-green': image.description}" iconPath="project-icons/pencil" width="14" height="14" viewBox="0 0 24 24" (click)="openEditImageDescriptionDialog(image)"></q-svg-wrapper>
                </div>
                <div class="icon-container close">
                  <svg class="small-icon pointer" viewBox="0 0 24 24" (click)="removeImgByIndex(i)">
                    <use xlink:href="assets/project-icons/close.svg#c"></use>
                  </svg>
                </div>
              </div>
            }
          </div>
        </form>
      }
    </div>

@if (editingImageDescriptionId) {
  <q-modal [title]="'editImageDescription' | transloco" [size]="'medium'" [footerButtons]="editDescriptionModalButtons" (closeEvent)="closeEditImageDescriptionDialog()">
    <mat-form-field appearance="fill" class="form-field mat-text-area" color="accent">
      <mat-label>{{'description' | transloco}}</mat-label>
      <textarea matInput [(ngModel)]="editingImageDescription" rows="5" maxlength="500"></textarea>
    </mat-form-field>
  </q-modal>
}

<input id="add-photo-input" style="display: none" type="file" accept="image/png, image/jpeg" />
