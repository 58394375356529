import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, catchError, map, Observable, of, share, take, throwError} from 'rxjs';
import moment from 'moment';
import {EnvProviderService} from './env-provider.service';
import {compare} from '../functions/array.functions';
import {
  isStartZoneCircle,
  QuestMetadata,
  QuestPublicationRequest,
  QuestStepFunctionalMetadata,
  QuestStepMetadata
} from '../interfaces/quest-core.interfaces';
import {QuestInfoParagraph, Tag} from '../interfaces/quest-info.interfaces';
import {Dict, PaginationData, QuestFilters, QuestGetOptions, TimeSpan} from '../interfaces/common.interfaces';
import {secondsToTime, timeToSeconds} from '../functions/date.functions';
import {QuestDifficulty, RecommendedFor} from '../interfaces/quest-info.enums';
import {
  isTypeMarkerFind,
  isTypeMarkerReach,
  QuestStepObjectiveMetadata
} from '../interfaces/quest-objectives.interfaces';
import {GeoCoords} from '../interfaces/geo-and-movement.interfaces';
import {distanceBetweenCoordinates} from '../functions/geo.functions';
import {Cluster} from '../interfaces/cluster.interfaces';
import {Organization} from '../interfaces/user.interfaces';

@Injectable({
  providedIn: 'root'
})
export class QuestsCrudService {
  readonly ROOT_URL = '/quests';
  private readonly quests$$ = new BehaviorSubject([]);

  constructor(private http: HttpClient, private env: EnvProviderService) {
  }

  get quests$() {
    return this.quests$$.asObservable();
  }

  updateQuestsSubject(quests: QuestMetadata[]) {
    this.quests$$.next(quests);
  }

  createQuest(quest: QuestMetadata): Observable<QuestMetadata> {
    const body = this.transformQuestForServer(quest);

    return this.http.post<any>(`${this.env.environment.API_URL}${this.ROOT_URL}/`, body).pipe(
      map((value) => this.transformQuestFromServer(value)),
    );
  }

  updateQuest(quest: QuestMetadata): Observable<unknown> {
    return this.http.put(`${this.env.environment.API_URL}${this.ROOT_URL}/${quest.id}`, this.transformQuestForServer(quest));
  }

  deleteQuest(id: string) {
    return this.http.delete(`${this.env.environment.API_URL}${this.ROOT_URL}/${id}`).pipe(take(1));
  }

  saveImages(id: number, images: {id?: number, blob?: Blob, description?: string}[], forWhat: 'quest' | 'step', startFrom = 1): Observable<void> {
    const formData = new FormData();

    for (let i = 1; i <= images.length; i++) {
      const imageData = images[i - 1];
      if (imageData.blob) {
        formData.append(`image_${i + startFrom}`, imageData.blob);
      }
      if (imageData.id) {
        formData.append(`image_id_${i + startFrom}`, imageData.id.toString());
      }
      formData.append(`description_${i + startFrom}`, imageData.description || '');
    }

    const partOfUrl = forWhat === 'quest' ? '' : '/step';

    return this.http.post<void>(`${this.env.environment.API_URL}${this.ROOT_URL}${partOfUrl}/${id}/upload-images`, formData);
  }

  deleteImage(imageId: string): Observable<void> {
    return this.http.delete<void>(`${this.env.environment.API_URL}${this.ROOT_URL}/image/${imageId}/delete`);
  }

  loadQuests(location?: GeoCoords,
             search?: string,
             liteVersion?: boolean,
             filters?: QuestFilters,
             options?: QuestGetOptions,
             pagination?: PaginationData
  ): Observable<QuestMetadata[] & { createdAgo?: string; updatedAgo?: string }> {
    return this.http.get<any[]>(
      `${this.env.environment.API_URL}${this.ROOT_URL}/`,
      {params: this.httpParamsForLoadQuests(location, search, liteVersion, filters, options, pagination)})
      .pipe(
        map((value) =>
          value?.map((quest) => ({
            ...this.transformQuestFromServer(quest),
            createdAgo: moment(quest.created_at).fromNow(),
            updatedAgo: moment(quest.updated_at).fromNow()
          }))
        ),
        map((value) => {
          return value.sort((a, b) => {
            return compare(a.updatedAt.getTime(), b.updatedAt.getTime(), false);
          })
        })
      );
  }

  loadClusters(location?: GeoCoords,
               search?: string,
               liteVersion?: boolean,
               filters?: QuestFilters,
               options?: QuestGetOptions,
               pagination?: PaginationData): Observable<{ clusters: Cluster[], quests: QuestMetadata[] }> {
    return this.http.get<{ clusters: any[], quests: any[] }>(`${this.env.environment.API_URL}${this.ROOT_URL}/clustered`,
      {params: this.httpParamsForLoadQuests(location, search, liteVersion, filters, options, pagination)}).pipe(
      map(value => {
        return {
          quests: value.quests.map(q => this.transformQuestFromServer(q)),
          clusters: value.clusters.map(c => this.transformClusterFromServer(c))
        };
      }),
    );
  }

  loadAllTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.env.environment.API_URL}${this.ROOT_URL}/tags`).pipe(share());
  }

  loadQuestById(questId: number, includePublicationRequests = false): Observable<QuestMetadata> {
    let params = new HttpParams();
    if (includePublicationRequests) {
      params = params.set('includePublicationRequests', true);
    }
    return this.http.get<any>(`${this.env.environment.API_URL}${this.ROOT_URL}/${questId}`, {params})
      .pipe(map((value) => this.transformQuestFromServer(value)));
  }

  countQuestsCreatedInLastDay$(): Observable<number> {
    const timestamp = new Date(new Date().getTime() - 86400000).toISOString();

    return this.http.get<{ count: number }>(
      `${this.env.environment.API_URL}${this.ROOT_URL}/created-by-datetime/${timestamp}`
    ).pipe(map((value) => value.count));
  }

  canEditQuest$(questId: number): Observable<boolean> {
    return this.http.get<void>(`${this.env.environment.API_URL}${this.ROOT_URL}/can-edit/${questId}`).pipe(
      map(() => true),
      catchError((err) => {
        if (err.status === 403 || err.status === 404) {
          return of(false);
        }

        return throwError(() => err);
      })
    )
  }

  submitShareToken(token: string): Observable<{ questId: number }> {
    return this.http.patch<any>(`${this.env.environment.API_URL}${this.ROOT_URL}/tokens/submit`, {token})
      .pipe(map(value => ({questId: value.quest_id})));
  }

  private httpParamsForLoadQuests(location?: GeoCoords,
                                  search?: string,
                                  liteVersion?: boolean,
                                  filters?: QuestFilters,
                                  options?: QuestGetOptions,
                                  pagination?: PaginationData): HttpParams {
    let params = new HttpParams();

    if (location) {
      params = params.set('location', `${location.lat},${location.lng}`);
    }

    if (search) {
      params = params.set('search', search);
    }

    if (liteVersion) {
      params = params.set('liteVersion', 'true');
    }

    if (options?.ignoreWithOneCompletion) {
      params = params.set('ignoreWithOneCompletion', 'true');
    }

    if (options?.sortByLocation) {
      params = params.set('sortByLocation', 'true');
    }

    if (options?.favoriteOnly) {
      params = params.set('favoriteOnly', 'true');
    }

    if (filters) {
      if (filters.difficulty) {
        // eslint-disable-next-line max-len
        const d = `${filters.difficulty.easy ? 'easy,' : ''}${filters.difficulty.medium ? 'medium,' : ''}${filters.difficulty.hard ? 'hard,' : ''}${filters.difficulty.master ? 'master,' : ''}`.slice(0, -1);

        params = params.set('difficulty', d);
      }

      if (filters.access) {
        let access = filters.access.shared ? 'collaborator,' + (filters.access.onlyCollaborative ? '' : 'player,' ) : ''

        // eslint-disable-next-line max-len
        const r = `${filters.access.public ? 'public,' : ''}${filters.access.private ? 'private,' : ''}${access}`.slice(0, -1);

        params = params.set('return', r);
      }

      if (filters.tags?.length) {
        params = params.set('tags', filters.tags.map(t => t.id).join(','));
      }

      if (filters.radius && location) {
        params = params.set('r', filters.radius);
      }

      if (filters.completedOnly) {
        params = params.set('completedOnly', 'true');
      } else if (filters.notCompletedOnly) {
        params = params.set('notCompletedOnly', 'true');
      }

      if (filters.generatedOnly) {
        params = params.set('generatedOnly', 'true');
      }

      if (!filters.selectDrafts) {
        params = params.set('selectDrafts', 'false');
      }

      if (filters.mapHeight) {
        params = params.set('mapHeight', filters.mapHeight);
      }

      if (filters.unchainedOnly) {
        params = params.set('unchainedOnly', 'true');
      }
    }

    if (options) {
      if (options.questCreatorView) {
        params = params.set('questCreatorView', 'true');
      }
    }

    if (pagination) {
      params = params.set('page', pagination.page);
      params = params.set('page_size', pagination.pageSize);
    }

    params = params.set('adaptiveAndNormal', 'true');

    return params;
  }

  private transformQuestForServer(quest: QuestMetadata): Dict<any> {
    const body = {
      name: quest.name,
      description: quest.description?.at(0)?.data,
      location_point: quest.locationPoint ? `${quest.locationPoint.lat},${quest.locationPoint.lng}` : undefined,
      team_mode: quest.teamMode,
      competitive_mode: quest.competitiveMode,
      config: quest.config,
      is_private: !quest.info?.public,
      recommended_for: quest.info?.recommendedFor,
      difficulty: quest.info?.difficulty.toLowerCase(),
      availability: quest.info?.availability,
      adaptive: quest.adaptive,
      address: quest.info?.address,
      game_access: quest.gameAccess.map((value) => ({
        id: value.id,
        access_type: value.accessType,
        config: value.config
      })),
      approx_distance: quest.info?.approxDistance,
      approximate_time: quest.info?.approxTime ? timeToSeconds(quest.info.approxTime) : null,
      approx_time: quest.info?.approxTime ? timeToSeconds(quest.info.approxTime) : null,
      is_draft: !!quest.isDraft,
      // rewards_exp: this.calculateQuestExperience(quest),
      tags: quest.info?.tags?.map((tag) => tag.id),
      steps: new Array<any>()
    };

    for (let i = 0; i < quest.steps.length; i++) {
      const step = quest.steps[i];
      const ts = {
        name: step.name,
        type: step.type,
        action_zone: step.actionZone,
        functional_metadata: step.functionalMetadata,
        final_description: step.finalDescription,
        order_number: i + 1
      };

      if (step.id) {
        ts['id'] = step.id;
      }

      body.steps.push(ts);
    }

    if (quest.organization) {
      body['organization'] = {
        id: quest.organization.id,
        name: quest.organization.name
      }
    }

    return body;
  }

  private transformQuestFromServer(raw: Dict<any>): QuestMetadata {
    const splitLP = raw['location_point']?.split(',');

    const rating = {
      value: (raw['positive_votes'] / raw['all_votes']) * 100,
      voters: raw['all_votes'],
      myVote: raw['cur_user_vote_id'] ? {value: raw['cur_user_vote'], voteId: raw['cur_user_vote_id']} : undefined
    };

    let images = [];

    if (Array.isArray(raw['images'])) {
      images = raw['images'].map(value => {
        let id = undefined;
        let description = undefined;
        if (typeof value !== 'string') {
          id = value.id;
          description = value.description;
          value = value.url;
        }

        return {id, url: value.includes('/') ? value : `${this.env.environment.API_URL}/images/${value}.jpg`, description};
      });
    }

    // if (raw['images']) {
    //   images.push({url: `${this.env.environment.API_URL}/preview/${raw['preview']}.png`});
    // }

    const steps = !raw.hasOwnProperty('steps') ? [] : raw['steps'].map((value: any) => {
      const functionalMetadata: QuestStepFunctionalMetadata = value.functional_metadata;

      if (!functionalMetadata.clueMetadata) {
        functionalMetadata.clueMetadata = [];
      }

      let stepImages = [];

      if (value.images) {
        stepImages = value.images.map((img) => {
          let id = undefined;
          let description = undefined;
          if (typeof img !== 'string') {
            id = img.id;
            description = img.description
            img = img.url;
          }

          return {
            id,
            name: img.split('/')[-1],
            url: img.includes('/') ? img : `${this.env.environment.API_URL}/images/${img}.jpg`,
            description
          }
        });
      }

      value.functional_metadata['images'] = stepImages;

      return {
        id: value.id,
        name: value.name,
        actionZone: value.action_zone.connections ? value.action_zone : null,
        functionalMetadata: value.functional_metadata,
        finalDescription: value.final_description,
      };
    });

    let approxTime: TimeSpan = typeof raw['approximate_time'] === 'number' ? secondsToTime(raw['approximate_time']) : raw['approximate_time'];
    if (approxTime && !approxTime.hours && !approxTime.minutes && !approxTime.seconds) {
      approxTime = undefined;
    }

    let publicationRequest: QuestPublicationRequest = null;
    if (raw['publication_requests'] && raw['publication_requests'].length > 0) {
      publicationRequest = {
        id: raw['publication_requests'][0].id,
        status: raw['publication_requests'][0].status,
        createdAt: new Date(raw['publication_requests'][0]['created_at']),
        updatedAt: new Date(raw['publication_requests'][0]['updated_at'])
      };
    }

    let organization: Organization = null;

    if (raw['organization']) {
      organization = {
        id: raw['organization'].id,
        name: raw['organization'].name,
        description: raw['organization'].description,
        secondaryLogo: raw['organization'].secondary_logo,
        primaryColor: raw['organization'].primary_color,
        secondaryColor: raw['organization'].secondary_color,
        type: raw['organization'].type,
      };
    }

    const quest: QuestMetadata = {
      id: raw['id'],
      name: raw['name'],
      description: QuestsCrudService.transformDescription(raw),
      createdAt: new Date(raw['created_at']),
      updatedAt: new Date(raw['updated_at']),
      creator: {
        email: raw['creator_email'],
        username: raw['creator_username'],
        firstName: raw['creator_first_name'],
        lastName: raw['creator_last_name'],
        isSuperUser: raw['creator_is_superuser']
      },
      organization,
      teamMode: raw['team_mode'],
      competitiveMode: raw['competitive_mode'],
      adaptive: raw['adaptive'],
      locationPoint: splitLP
        ? {
          lat: Number(splitLP[0]),
          lng: Number(splitLP[1])
        }
        : undefined,
      isDraft: raw['is_draft'],
      config: raw['config'],
      publicationRequest,
      steps,
      info: {
        approxTime,
        approxDistance: raw['approx_distance'],
        approved: raw['approved'],
        address: raw['address'],
        images,
        difficulty: raw['difficulty'] ? QuestDifficulty[raw['difficulty']] : null,
        rewards: {
          exp: raw['rewards_exp']
        },
        rating,
        public: raw.hasOwnProperty('public') ? raw['public'] : !raw['is_private'],
        recommendedFor: raw['recommended_for'] ? raw['recommended_for'] : RecommendedFor.walk,
        availability: raw['availability']
          ? {
            type: raw['availability'].type,
            data: raw['availability'].data
          }
          : undefined,
        tags: raw['tags']
      },
      gameAccess: !raw['game_access'] ? null : raw['game_access'].map((value: any) => ({
        id: value.id,
        accessType: value.access_type,
        config: value.config
      })),
      accessLevel: raw['access_level'],
    };

    // we don't need (for now at least) to transform the dates to Date
    // if (quest.info.availability && quest.info.availability.data) {
    //   if (typeof quest.info.availability.data.fromDate === 'string' || typeof quest.info.availability.data.fromDate === 'number') {
    //     quest.info.availability.data.fromDate = new Date(quest.info.availability.data.fromDate);
    //   } else if (quest.info.availability.data.fromDate) {
    //     quest.info.availability.data.fromDate = new Date(
    //       quest.info.availability.data.fromDate.year,
    //       quest.info.availability.data.fromDate.month,
    //       quest.info.availability.data.fromDate.day
    //     );
    //   }
    //
    //   if (typeof quest.info.availability.data.toDate === 'string' || typeof quest.info.availability.data.toDate === 'number') {
    //     quest.info.availability.data.toDate = new Date(quest.info.availability.data.toDate);
    //   } else if (quest.info.availability.data.toDate) {
    //     quest.info.availability.data.toDate = new Date(
    //       quest.info.availability.data.toDate.year,
    //       quest.info.availability.data.toDate.month,
    //       quest.info.availability.data.toDate.day
    //     );
    //   }
    // }

    return quest;
  }

  private transformClusterFromServer(raw: Dict<any>): Cluster {
    return {
      id: raw['id'],
      itemsCount: raw['count'],
      center: {
        lat: raw['center'].lat,
        lng: raw['center'].lng
      },
      bbox: {
        max: {
          lat: raw['bbox'].max_lat,
          lng: raw['bbox'].max_lng
        },
        min: {
          lat: raw['bbox'].min_lat,
          lng: raw['bbox'].min_lng
        }
      }
    }
  }

  private static transformDescription(raw: any): QuestInfoParagraph[] {
    if (typeof raw.description === 'string') {
      return [{data: raw.description, type: 'text'}];
    }

    return raw.description;
  }

  // deprecated
  // TODO remove later
  private calculateQuestExperience(quest: QuestMetadata): number {
    let totalApproxDistanceInKm = this.calculateTotalApproxQuestDistance(quest);
    let approxTimeInSeconds = this.calculateApproxTimeForDistance(totalApproxDistanceInKm);

    let totalCoefficient = 1;

    //calculating quest timer coefficient
    if (quest.config.timer) {
      totalCoefficient += 0.05;

      totalCoefficient += this.deltaInTimeToCoefficient(approxTimeInSeconds * 1.5 - timeToSeconds(quest.config.timer));
    }

    for (let i = 0; i < quest.steps.length; i++) {
      const step = quest.steps[i];

      for (const obj of step.functionalMetadata.objectives) {
        //the bigger the task area is, the more experience the player will get
        if (isTypeMarkerFind(obj)) {
          //0.03 for the experience multiplier per 30m of task area radius
          totalCoefficient += (obj.marker.data.radius / 30) * 0.03;
        }
      }

      if (step.functionalMetadata.timer) {
        let stepPointsForDist = new Array<GeoCoords>();
        if (step.functionalMetadata.startZone) {
          totalCoefficient += 0.1;

          const startZoneCoords = this.getCoordsOfStepStartZone(step);

          //if this step has start zone, the start zone is the start of the step.
          //if this step doesn't have a start zone, and is not the first step, we treat the last objective coords of the last step as a start of this one
          if (startZoneCoords) {
            stepPointsForDist.push(startZoneCoords);
          } else if (i > 0) {
            stepPointsForDist.push(this.getQuestStepObjectiveCoords(quest.steps[i - 1].functionalMetadata.objectives.at(-1)));
          }
        }

        stepPointsForDist = [...stepPointsForDist, ...step.functionalMetadata.objectives.map((value) => this.getQuestStepObjectiveCoords(value))];

        if (stepPointsForDist.length < 2) {
          continue;
        }

        const stepDistance = distanceBetweenCoordinates(stepPointsForDist) * 1.5;

        //+ to the coefficient just because there is a timer;
        totalCoefficient += 0.05;

        const timerInSeconds = timeToSeconds(step.functionalMetadata.timer);

        totalCoefficient += this.deltaInTimeToCoefficient(this.calculateApproxTimeForDistance(stepDistance) * 1.5 - timerInSeconds);
      }
    }

    //more steps - more experience
    totalCoefficient += Math.exp(quest.steps.length / 10) - 1;

    let exp = 5 * totalApproxDistanceInKm * totalCoefficient;

    if (exp - Math.floor(exp) < 0.5) {
      exp = Math.floor(exp);
    } else {
      exp = Math.ceil(exp);
    }

    return exp;
  }

  private deltaInTimeToCoefficient(deltaInSeconds: number): number {
    if (deltaInSeconds <= 0) {
      return 0;
    }

    //for every 30 secs in the difference we give 0.005 to the experience coefficient
    return Math.exp((deltaInSeconds / 30) * 0.002) - 1;
  }

  //in km
  private calculateTotalApproxQuestDistance(quest: QuestMetadata): number {
    let points = new Array<GeoCoords>();

    for (const step of quest.steps) {
      const startZoneCoords = this.getCoordsOfStepStartZone(step);
      if (startZoneCoords) {
        points.push(startZoneCoords);
      }

      for (const obj of step.functionalMetadata.objectives) {
        points.push(this.getQuestStepObjectiveCoords(obj));
      }
    }

    return distanceBetweenCoordinates(points) * 1.5;
  }

  private getCoordsOfStepStartZone(step: QuestStepMetadata): GeoCoords | null {
    if (!step.functionalMetadata.startZone) {
      return null;
    }

    if (isStartZoneCircle(step.functionalMetadata.startZone)) {
      return step.functionalMetadata.startZone.coords;
    } else {
      return step.functionalMetadata.startZone.vertices[step.functionalMetadata.startZone.connections[0].startVertexId].coords;
    }
  }

  private getQuestStepObjectiveCoords(obj: QuestStepObjectiveMetadata): GeoCoords {
    if (isTypeMarkerReach(obj)) {
      return obj.marker.coords;
    } else if (isTypeMarkerFind(obj)) {
      return obj.marker.coords;
    }

    throw Error('Objective type not supported ' + obj.type);
  }

  /**
   * Calculates approximate time per distance. Returns the amount of seconds
   * @param distance im km
   * @private
   */
  private calculateApproxTimeForDistance(distance: number): number {
    const walkingSpeed = 4.5; // km per hour;

    const hours = distance / walkingSpeed;

    return hours * 3600;
  }
}
