import {
  GeoCoords,
  QGraph,
  QGraphVertexWithCircle,
  QGraphVertexWithRadius
} from '../interfaces/geo-and-movement.interfaces';
import * as mapboxgl from 'mapbox-gl';
import {isObeliskZoneCircle, Obelisk, Pathway} from '../interfaces/exploration.interfaces';
import {ObeliskImportance} from '../interfaces/obelisks.enums';
import {Cluster} from '../interfaces/cluster.interfaces';
import {Dict} from '../interfaces/common.interfaces';
import {zoneToLineString} from './map-general.functions';

export const obelisksToZones = (obelisks?: Obelisk[]): (QGraph | QGraphVertexWithCircle | QGraphVertexWithRadius)[] => {
  return obelisks.filter(value => !!value).map((obelisk) => {
    if (!isObeliskZoneCircle(obelisk.zone)) {
      return obelisk.zone.absolutePolygon;
    }

    return {
      coords: obelisk.location,
      data: {radius: obelisk.zone.radius}
    } as QGraphVertexWithRadius;
  });
}

export const obeliskImageUriToThumbnail = (uri: string): string => {
  if (!uri) {
    return '';
  }

  if (!uri.includes('thumbnails') && uri.includes('obelisk_images')) {
    uri = uri.replace('obelisk_images', 'obelisk_images/thumbnails');
  }

  return uri;
}

export const drawObelisk = (obelisk: Obelisk, map: mapboxgl.Map, onClick = () => {}): mapboxgl.Marker => {
  if (!map) {
    return null;
  }

  let dimensions = 42;
  let size = 'big';


  switch (obelisk.importance) {
    case ObeliskImportance.small:
      dimensions = 28;
      size = 'small';
      break;
    case ObeliskImportance.medium:
      dimensions = 36;
      size = 'medium';
      break;
  }
  const div = document.createElement('div');
  div.className = 'map-obelisk-marker-container ' + size;
  if (obelisk.approved) {
    div.className += ' approved';
  }

  const label = document.createElement('div');
  label.className = 'label';
  label.innerText = obelisk.name;
  div.appendChild(label);

  const mainBody = document.createElement('div');
  mainBody.className = 'main-body';
  div.appendChild(mainBody);

  const pin = document.createElement('div');
  pin.className = 'pin';
  div.appendChild(pin);

  if (obelisk.images && obelisk.images.length > 0) {
    const img = document.createElement('img');

    img.src = obeliskImageUriToThumbnail(obelisk.images[0].uri);
    img.width = dimensions;
    img.height = dimensions;
    img.style.backgroundColor = 'lightgrey';

    mainBody.appendChild(img);
  } else {
    if (obelisk.svg?.includes('svg')) {
      obelisk.svg = obelisk.svg.replace('width="24" height="24"', `width="${dimensions}" height="${dimensions}"`);

      const svg = document.createElement('div');
      svg.style.width = `${dimensions}px`;
      svg.style.height = `${dimensions}px`;
      svg.innerHTML = obelisk.svg;

      mainBody.appendChild(svg);
    } else {
      const img = document.createElement('img');

      img.src = `assets/exploration-icons/${obelisk.svg}.svg`;
      img.width = dimensions;
      img.height = dimensions;

      mainBody.appendChild(img);
    }
  }

  const marker = new mapboxgl.Marker(div).setLngLat(obelisk.location).addTo(map);
  mainBody.addEventListener('click', (ev: any) => {
    onClick();

    ev.stopPropagation();
  });

  return marker;
}

export const transformClusterFromServer = (raw: Dict<any>): Cluster => {
  return {
    id: raw['id'],
    itemsCount: raw['count'],
    center: {
      lat: raw['center'].lat,
      lng: raw['center'].lng
    },
    bbox: {
      max: {
        lat: raw['bbox'].max_lat,
        lng: raw['bbox'].max_lng
      },
      min: {
        lat: raw['bbox'].min_lat,
        lng: raw['bbox'].min_lng
      }
    }
  }
}

export const drawPathway = (pathway: Pathway, map: mapboxgl.Map) => {
  const id = 'pathway-' + pathway.uuid;

  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  });

  map.addLayer({
    id: id + '-outline',
    type: 'line',
    source: id,
    layout: {},
    paint: {
      'line-color': '#efefef',
      'line-width': 4,
    }
  });

  map.addLayer({
    id,
    type: 'line',
    source: id,
    layout: {},
    paint: {
      'line-color': pathway.color,
      'line-width': 2,
    }
  });

  map.once('styledata', () => {
    (map.getSource(id) as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {},
        geometry: zoneToLineString(pathway.path)
      }]
    });
  });
}

export const removePathway = (pathway: Pathway, map: mapboxgl.Map) => {
  const id = 'pathway-' + pathway.uuid;

  if (map.getLayer(id)) {
    map.removeLayer(id);
  }

  if (map.getLayer(id + '-outline')) {
    map.removeLayer(id + '-outline');
  }

  if (map.getSource(id)) {
    map.removeSource(id);
  }
}

export const drawComment = (coords: GeoCoords, comment: string, imageUrl: string | undefined, map: mapboxgl.Map, active = false, onImageClick?: (imageUrl: string) => void): mapboxgl.Popup => {
  let actualComment = '';

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    className: 'cursor-pointer'
  })
    .setLngLat([coords.lng, coords.lat])
    .setHTML(`<div class="regular-14 comment-on-map-content"></div>`)
    .addTo(map);

  const updateComment = (c: string, u?: string, a?: boolean) => {
    if (a !== undefined) {
      active = a;
    }

    if (!c) {
      c = '';
    }

    actualComment = active ? c : '...';
    u = active ? imageUrl : undefined;

    popup.setHTML(`
<div class="regular-14 comment-on-map-content ${active ? '' : 'unfocused'}">
${u ? `<img src="${u}" alt="NO IMG">` : ''}
${actualComment}
</div>`);

    if (u) {
      popup.getElement().querySelector('img').addEventListener('click', (event) => {
        if (active && onImageClick) {
          onImageClick(u);

          event.stopPropagation();
        }
      });
    }

    if (active) {
      map.once('click', () => {
        updateComment(c, u, false);
      })
    }
  }

  updateComment(comment, imageUrl, active);

  popup.getElement().addEventListener('click', () => {
    active = !active;

    updateComment(comment, imageUrl);
  });

  return popup;
}
