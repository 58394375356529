import {AfterContentInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FormComponent , OptionsFormData} from '../form-component.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestDropdownValues } from '../../../common-components/dropdown/dropdown.component';
import {
  updateFormValuesObjectFromIDB
} from '../map-editor/form-component-general.functions';
import { debounceTime, filter, Subject, takeUntil } from 'rxjs';
import { getDifficultyOptions} from '../dropdown-options-for-forms';
import {readImageFromClipboard} from '../../../functions/file.functions';
import {QuestsCrudService, numberToAtLeastTwoCharsStr, Tag, ImgInfoBody, ModalButton} from 'quest-atlas-angular-components';
import {timerFromString} from 'quest-atlas-angular-components';
import {IDB_NAME, IDB_VERSION} from '../quest-editor.constants';
import {UserService} from '../../../services/user.service';
import {idbOptionsFormStore} from '../quest-editor.functions';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-options-form',
  templateUrl: './options-form.component.html',
  styleUrls: ['./options-form.component.scss']
})
export class OptionsFormComponent implements OnInit, AfterContentInit, FormComponent, OnDestroy {
  readonly MAX_NUMBER_OF_IMAGES = 7;
  readonly MAX_NUMBER_OF_TAGS = 5;
  tagsSelectedCount = 0

  type = 'options';

  @Input() adaptive = false;
  @Input() formValues: OptionsFormData = {};

  form: FormGroup;
  db: IDBDatabase;

  @Input() cacheFormData = true;

  difficultyOptions: QuestDropdownValues[] = getDifficultyOptions();

  tags: Tag[] = [];
  defaultMinDate = new Date(1970, 1, 1);
  defaultMaxDate = new Date(2100, 1, 1);

  editingImageDescriptionId?: number;
  editingImageDescription = '';
  editDescriptionModalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('cancel'),
      onClick: () => {
        this.closeEditImageDescriptionDialog();
      }
    },
    {
      type: 'primary',
      text: this.transloco.translate('save'),
      onClick: () => {
        this.submitEditImageDescription();
      }
    }
  ];

  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private questCrudService: QuestsCrudService,
              private userService: UserService,
              private transloco: TranslocoService) {}

  ngOnInit(): void {
    this.questCrudService.loadAllTags().pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.tags = value;

      if (this.formValues?.tags) {
        this.formValues.tags.forEach(tag => tag.selected = true);
        this.tagsSelectedCount = this.formValues.tags.length;

        for (const t of this.tags) {
          if (this.formValues?.tags?.findIndex(tv => tv.id === t.id) !== -1) {
            t.selected = true;
          }
        }
      }
    });

    if (this.cacheFormData) {
      const request = window.indexedDB.open(IDB_NAME, IDB_VERSION);

      request.onerror = (event) => {
        console.error('IndexedDB error:', event);
      };
      request.onsuccess = (event) => {
        this.db = (event.target as any).result;

        updateFormValuesObjectFromIDB(this.formValues, idbOptionsFormStore(this.adaptive), this.db).pipe(takeUntil(this.destroy$)).subscribe({
          next: lsv => {
            if (lsv['approxTime'] && !lsv['approxTime'].hasOwnProperty('hours')) {
              lsv['approxTime'] = timerFromString(lsv['approxTime']);
            }

            this.formValues = lsv;

            this.initForm();
          },
          error: () => {
            this.initForm();
          }
        });
      };
    } else {
      this.initForm();
    }
  }

  private initForm(): void {
    const hrs = numberToAtLeastTwoCharsStr(this.formValues?.approxTime?.hours)
    const mins = numberToAtLeastTwoCharsStr(this.formValues?.approxTime?.minutes)
    const secs = numberToAtLeastTwoCharsStr(this.formValues?.approxTime?.seconds)
    const at = this.formValues?.approxTime ? `${hrs}:${mins}:${secs}` : undefined;

    this.form = this.fb.group({
      difficulty: [this.formValues?.difficulty, [Validators.required]],
      fromDate: [this.formValues.fromDate],
      toDate: [this.formValues.toDate],
      description: [this.formValues.description],
      address: [this.formValues.address],
      uploadedImages: [this.formValues?.uploadedImages ? this.formValues?.uploadedImages : []],
      onlyOneCompletion: [this.formValues?.onlyOneCompletion],
      stepsHidden: [this.formValues?.stepsHidden],
      levelRequired: [this.formValues?.levelRequired],
      approxDistance: [this.formValues?.approxDistance],
      approxTime: [at],
      tags: [this.formValues?.tags ? this.formValues?.tags : []]
    });

    this.form.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => this.cacheFormData),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        if (this.db) {
          const objectStore = this.db.transaction(idbOptionsFormStore(this.adaptive), 'readwrite').objectStore(idbOptionsFormStore(this.adaptive));
          objectStore.clear();
          objectStore.put(value);
        }
      });
  }

  ngAfterContentInit(): void {
    const addPhotoInput = document.getElementById('add-photo-input');
    addPhotoInput!.addEventListener('change', (event: any) => {
      const files = event?.target?.files;
      if (!files || files.length === 0) {
        console.error('No files uploaded');
        return;
      }

      this.readImage(files[0]);
    });
  }

  addPhotoClick(): void {
    if (this.getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES) {
      return;
    }

    const addPhotoInput = document.getElementById('add-photo-input');
    addPhotoInput?.click();
  }

  clickPastePhoto(): void {
    if (this.getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES) {
      return;
    }

    readImageFromClipboard()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.readImage(value);
        }
      });
  }

  removeImgByIndex(i: number): void {
    const images = this.getCurrentImages();
    let imagesNew = images.slice(0, i);
    imagesNew = [...imagesNew, ...images.slice(i + 1)];

    this.form.get('uploadedImages')?.setValue(imagesNew);
  }

  // TODO респонс від логіну через фб може йти якийсь час. Треба лоадер, або не дозволяти клікати кнопку ще раз, поки грузиться
  isFormValid(): boolean {
    return !!this.form?.valid;
  }

  submitForm(): any {
    const value = { ...this.form.value };
    value.approxTime = value.approxTime ? timerFromString(value.approxTime) : undefined;
    return value;
  }

  getFormData(): any {
    return this.form.value;
  }

  getCurrentImages(): any[] {
    return this.form.get('uploadedImages')!.value;
  }

  clickOnTag(tag: Tag): void {
    if (tag.selected) {
      this.form.get('tags')?.setValue(this.form.get('tags')?.value.filter((value: Tag) => value.id !== tag.id));
    } else {
      if (this.form.get('tags')?.value.length >= this.MAX_NUMBER_OF_TAGS) {
        return;
      }

      this.form.get('tags')?.setValue([...this.form.get('tags')?.value, tag]);
    }

    this.tagsSelectedCount += tag.selected ? -1 : 1;

    tag.selected = !tag.selected;
  }

  openEditImageDescriptionDialog(image: ImgInfoBody): void {
    this.editingImageDescriptionId = image.id !== undefined ? image.id : image.tempId;
    this.editingImageDescription = image.description || '';
  }

  closeEditImageDescriptionDialog(): void {
    this.editingImageDescriptionId = null;
    this.editingImageDescription = '';
  }

  submitEditImageDescription(): void {
    const images = this.getCurrentImages();
    const image = images.find((i: ImgInfoBody) => i.id === this.editingImageDescriptionId || i.tempId === this.editingImageDescriptionId);
    image.description = this.editingImageDescription;

    this.form.get('uploadedImages')?.setValue(images);
    this.closeEditImageDescriptionDialog();
  }

  private readImage(file: any): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.form.get('uploadedImages')?.setValue([...this.form.get('uploadedImages')?.value, { base: reader.result, raw: file, tempId: this.getCurrentImages().length + 1 }]);

      reader.removeAllListeners('load');
    });

    reader.readAsDataURL(file);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
