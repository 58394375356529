import {Component, effect, ElementRef, HostListener, input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SafeHtml} from '@angular/platform-browser';

export interface QuestDropdownValues {
  imgName?: string;
  imgSvg?: SafeHtml;
  imgUrl?: string;
  value: any;
  title: string;
  description?: string;
  disabled?: boolean;
}

export const isQuestDropdownValues = (obj: any): obj is QuestDropdownValues => {
  return obj?.hasOwnProperty('value') && obj?.hasOwnProperty('title');
}

export const covertToQuestDropdownValue = (value: QuestDropdownValues | any, options: QuestDropdownValues[]): QuestDropdownValues => {
  if (!isQuestDropdownValues(value)) {
    for (const opt of options) {
      if (opt.value === value) {
        return opt;
      }
    }
  }
  return undefined;
}

// later is has to support the accessibility as in angular material https://material.angular.io/components/select/overview
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownComponent
    }
  ]
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  title = input<string>('');
  options = input.required<QuestDropdownValues[]>();
  optional = input<boolean>(false);

  active = false;
  selectedValue!: QuestDropdownValues;

  onChange = (value: QuestDropdownValues) => {};

  onTouched = () => {};

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.active = false;
    }
  }

  @HostListener('document:keyup', ['$event'])
  keyup(event: any) {
    if (this.active && event.key === 'Escape')
    this.active = false;
  }

  constructor(private eRef: ElementRef) {
    effect(() => {
      // this is just to subscribe to the changes of the options
      const o = this.options();

      if (this.selectedValue && !o.find(opt => opt.value === this.selectedValue.value)) {
        this.selectedValue = null;
      }
    });
  }

  ngOnInit(): void {
    // this.selectedValue = {value: null, title: ''}
  }

  chooseValue(option: QuestDropdownValues): void {
    if (option.disabled) {
      return;
    }

    this.selectedValue = option;
    this.active = false;
    this.onChange(option);
  }

  clearValue(): void {
    this.selectedValue = null;
    this.active = false;
    this.onChange(null);
  }

  clickOnComponent(): void {
    this.active = !this.active;
  }

  registerOnChange(fn: (value: QuestDropdownValues) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(value: QuestDropdownValues): void {
    this.selectedValue = value;
  }

}
